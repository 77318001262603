import { FC, useState, createContext } from 'react';
import { eaService } from 'src/services';
import { Trans, useTranslation } from 'react-i18next';
import { CURRENT_USER } from 'src/constants';
import { TrainingReport } from 'src/models/training/trainingReport.model';

type EaContext = {
	idEa: number;
	setIdEaContext: (dataValue) => void;
	idUser: number;
	setIdUserContext: (dataValue) => void;
	setEmployeeIdentityDataContext: (dataValue) => void;
	setEmployeeAppreciationDataContext: (dataValue) => void;
	setManagerAppreciationDataContext: (dataValue) => void;
	setCommentaireDataContext: (dataValue) => void;
	setEvolutionProfessionnelleDataContext: (dataValue) => void;
	setSyntheseDataContext: (dataValue) => void;
	setEvaluationPerformanceDataContext: (dataValue) => void;
	setIsDCUpToDateDataContext: (dataValue) => void;
	setFormationComptePersoDataContext: (dataValue) => void;
	setEaDataAsync: () => any;
	isValidData: () => any;
	setExperienceCommentDataContext: (dataValue) => void;
	// data check
	isDCUpToDateData: number;
	interviewDateNManagerKO: boolean;
	setInterviewDateNManagerKOContext: (dataalue) => void;
	mobilityDataKO: boolean;
	setMobilityDataKOContext: (dataValue) => void;
	//check if data change
	isDataChange: boolean;
	setIsDataChangeContext: (dataValue) => void;

	evaluationCompetenceNm1Data: any;
	setEvaluationCompetenceNm1DataContext: (dataValue) => void;
	trainingReportData: TrainingReport[];
	setTrainingReportDataContext: (dataValue: TrainingReport[]) => void;
	objectifNPlus1Data: any;
	setObjectifNPlus1DataContext: (dataValue) => void;
	trainingReportDataKO: boolean;
	evaluationCompetenceNm1DataKO: boolean;
	objectifNPlus1DataKO: boolean;
	setObjectifNPlus1DataKOContext: (dataValue) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EaContext = createContext<EaContext>(
	{} as EaContext
);

export const EaProvider: FC = ({ children }) => {
	const { t } = useTranslation();
	const userData = JSON.parse(localStorage.getItem(CURRENT_USER));
	const [idEa, setIdEa] = useState(0);
	const [idUser, setIdUser] = useState(0);
	const [isDataChange, setIsDataChange] = useState(false);
	const [evaluationCompetenceNm1Data, setEvaluationCompetenceNm1Data] = useState(0);
	const [trainingReportData, setTrainingReportData] = useState<TrainingReport[]>();
	const [trainingReportDataKO, settrainingReportDataKO] = useState<boolean>(false);

	const setTrainingReportDataContext = (dataValue) => {
		if (dataValue != null) {
			if (dataValue.length != 0) {
				for (let i = 0; i < dataValue.length; i++) {
					if (dataValue[i].evaluation == 0 || dataValue[i].evaluation == null) {
						settrainingReportDataKO(true);
						break;
					} else {
						settrainingReportDataKO(false);
					}
				}
			} else {
				settrainingReportDataKO(false);
			}
		} else {
			settrainingReportDataKO(false);
		}
		setTrainingReportData(dataValue)
	}

	const [objectifNPlus1Data, setObjectifNPlus1Data] = useState();
	const [objectifNPlus1DataKO, setObjectifNPlus1DataKO] = useState<boolean>(false);

	const setObjectifNPlus1DataKOContext = (dataValue) => {
		setObjectifNPlus1DataKO(dataValue)
	}
	const setObjectifNPlus1DataContext = (dataValue) => {
		// check data
		if (dataValue != null) {
			if (dataValue.length != 0) {
				setObjectifNPlus1DataKO(false);
			} else {
				setObjectifNPlus1DataKO(true);
			}
		} else {
			setObjectifNPlus1DataKO(true);
		}

		setObjectifNPlus1Data(dataValue)
	}
	const setIsDataChangeContext = (dataValue) => {
		setIsDataChange(dataValue)
	}
	const setIdEaContext = (dataValue) => {
		setIdEa(dataValue)
	}
	const setIdUserContext = (dataValue) => {
		setIdUser(dataValue)
	}
	const [employeeIdentityData, setEmployeeIdentityData] = useState({
		experience: 0,
		society: '',
		dateNaissance: '',
		dateEntreeAstek: '',
		function: '',
		dateEntretienN: '',
		dateEntretienNm1: ''
	});
	const [employeeAppreciationData, setEmployeeAppreciationData] = useState({
		contributionsConsultant: '',
		strengthsConsultant: '',
		weaknessesConsultant: ''
	});

	const [managerAppreciationData, setManagerAppreciationData] = useState({
		contributionsManager: '',
		strengthsManager: '',
		weaknessesManager: ''
	});

	const [commentaireData, setCommentaireData] = useState({
		comment: ''
	});

	const [evolutionProfessionnelleData, setEvolutionProfessionnelleData] = useState({
		shortTermGoal: '',
		shortTermMotivation: '',
		shortTermWay: '',
		mediumTermGoal: '',
		mediumTermMotivation: '',
		mediumTermWay: '',
		longTermGoal: '',
		longTermMotivation: '',
		longTermWay: '',
		mobility: '',
		otherActivity: '',
		otherWill: ''
	});

	const [synthese, setSynthese] = useState({
		synthesis: '',
		managerSignedAt: ''
	});

	const [evaluationPerformanceData, setEvaluationPerformanceData] = useState(0);
	const [isDCUpToDateData, setIsDCUpToDateData] = useState(0);
	const [interviewDateNManagerKO, setInterviewDateNManagerKO] = useState<boolean>(false);
	const [mobilityDataKO, setMobilityDataKO] = useState<boolean>(false);
	const [evaluationCompetenceNm1DataKO, setEvaluationCompetenceNm1DataKO] = useState<boolean>(false);

	const setMobilityDataKOContext = (dataValue) => {
		setMobilityDataKO(dataValue);
	};

	const setInterviewDateNManagerKOContext = (dataValue) => {
		setInterviewDateNManagerKO(dataValue);
	};

	const [formationComptePersoData, setFormationComptePersoData] = useState({
		cpfHours: null,
		cpfDate: null,
		cpfIsOkTraining: false,
		commentTrainingRequest: ''
	});

	const [experienceCommentData, setExperienceCommentData] = useState("");

	const setExperienceCommentDataContext = (dataValue) => {
		setExperienceCommentData(dataValue);
	};

	const setEmployeeIdentityDataContext = (dataValue) => {
		setEmployeeIdentityData({ ...dataValue });
	};

	const setEmployeeAppreciationDataContext = (dataValue) => {
		setEmployeeAppreciationData({ ...dataValue });
	};

	const setManagerAppreciationDataContext = (dataValue) => {
		setManagerAppreciationData({ ...dataValue });
	};

	const setCommentaireDataContext = (dataValue) => {
		setCommentaireData({ ...dataValue });
	};

	const setEvolutionProfessionnelleDataContext = (dataValue) => {
		setEvolutionProfessionnelleData({ ...dataValue });
	};

	const setSyntheseDataContext = (dataValue) => {
		setSynthese({ ...dataValue });
	};

	const setEvaluationPerformanceDataContext = (dataValue) => {
		setEvaluationPerformanceData(dataValue);
	};

	const setEvaluationCompetenceNm1DataContext = (dataValue) => {
		setEvaluationCompetenceNm1Data(dataValue);
		// check data
		if (dataValue == 0 || dataValue == '' || dataValue == null) {
			setEvaluationCompetenceNm1DataKO(true);
		} else {
			setEvaluationCompetenceNm1DataKO(false);
		}
	}

	const setIsDCUpToDateDataContext = (dataValue) => {
		setIsDCUpToDateData(dataValue);
	}

	const setFormationComptePersoDataContext = (dataValue) => {
		setFormationComptePersoData({ ...dataValue });
	};

	const setEaDataAsync = async () => {
		let request = {
			id: idEa,
			idUser: idUser,
			isDCUpToDate: isDCUpToDateData == 1 ? true : false,
			cadreEntretien: {
				experience: employeeIdentityData.experience,
				society: employeeIdentityData.society,
				dateNaissance: employeeIdentityData.dateNaissance == '' ? null : employeeIdentityData.dateNaissance,
				dateEntreeAstek: employeeIdentityData.dateEntreeAstek == '' ? null : employeeIdentityData.dateEntreeAstek,
				function: employeeIdentityData.function,
				dateEntretienN: employeeIdentityData.dateEntretienN == '' ? null : employeeIdentityData.dateEntretienN,
				dateEntretienNm1: employeeIdentityData.dateEntretienNm1 == '' ? null : employeeIdentityData.dateEntretienNm1
			},
			bilanAnnuel: {
				experienceComment: experienceCommentData,
				appreciationSalarie: {
					contributionsConsultant: employeeAppreciationData.contributionsConsultant,
					strengthsConsultant: employeeAppreciationData.strengthsConsultant,
					weaknessesConsultant: employeeAppreciationData.weaknessesConsultant
				},
				appreciationManager: {
					contributionsManager: managerAppreciationData.contributionsManager,
					strengthsManager: managerAppreciationData.strengthsManager,
					weaknessesManager: managerAppreciationData.weaknessesManager
				},
				evaluationPerformance: {
					evaluation: evaluationPerformanceData
				},
				commentaire: {
					comment: commentaireData.comment
				}
			},
			evolutionProfessionnelle: {
				shortTermGoal: evolutionProfessionnelleData.shortTermGoal,
				shortTermMotivation: evolutionProfessionnelleData.shortTermMotivation,
				shortTermWay: evolutionProfessionnelleData.shortTermWay,
				mediumTermGoal: evolutionProfessionnelleData.mediumTermGoal,
				mediumTermMotivation: evolutionProfessionnelleData.mediumTermMotivation,
				mediumTermWay: evolutionProfessionnelleData.mediumTermWay,
				longTermGoal: evolutionProfessionnelleData.longTermGoal,
				longTermMotivation: evolutionProfessionnelleData.longTermMotivation,
				longTermWay: evolutionProfessionnelleData.longTermWay,
				mobility: evolutionProfessionnelleData.mobility,
				otherActivity: evolutionProfessionnelleData.otherActivity,
				otherWill: evolutionProfessionnelleData.otherWill
			},
			evaluationCompetence: {
				evaluationCompetenceNm1: evaluationCompetenceNm1Data
			},
			synthese: {
				synthesis: synthese.synthesis
			},
			cpfHours: formationComptePersoData.cpfHours == '' ? null : parseInt(formationComptePersoData.cpfHours),
			cpfDate: formationComptePersoData.cpfDate == '' ? null : formationComptePersoData.cpfDate,
			cpfIsOkTraining: formationComptePersoData.cpfIsOkTraining,
			commentTrainingRequest: formationComptePersoData.commentTrainingRequest,
			UpdatedBy: userData.id,
			errorMessage: ""
		}

		await eaService.updateEaAsync(request).then(response => {
			if (!response.data.success) {
				request.errorMessage = '- ' + response.data.message;
			}
		}).catch(err => {
			request.errorMessage = '- ' + err.response.data.message;
		});
		return request;
	}

	const isValidData = async () => {
		let isValid = true;
		let requestData = await setEaDataAsync();
		if (userData.idRole == 1) {
			if (requestData.evolutionProfessionnelle.mobility == '' || !requestData.isDCUpToDate) {
				isValid = false;
			}
		}
		return isValid;
	}

	return (
		<>
			<EaContext.Provider value={{
				idEa,
				setIdEaContext,
				idUser,
				setIdUserContext,
				setEmployeeIdentityDataContext,
				setEmployeeAppreciationDataContext,
				setManagerAppreciationDataContext,
				setCommentaireDataContext,
				setEvolutionProfessionnelleDataContext,
				setSyntheseDataContext,
				setEvaluationPerformanceDataContext,
				setIsDCUpToDateDataContext,
				setFormationComptePersoDataContext,
				setEaDataAsync,
				isValidData,
				setExperienceCommentDataContext,
				// data check
				isDCUpToDateData,
				interviewDateNManagerKO,
				setInterviewDateNManagerKOContext,
				mobilityDataKO,
				setMobilityDataKOContext,
				//check if data change
				isDataChange,
				setIsDataChangeContext,

				evaluationCompetenceNm1Data,
				setEvaluationCompetenceNm1DataContext,
				trainingReportData,
				setTrainingReportDataContext,
				objectifNPlus1Data,
				setObjectifNPlus1DataContext,
				trainingReportDataKO,
				evaluationCompetenceNm1DataKO,
				objectifNPlus1DataKO,
				setObjectifNPlus1DataKOContext
			}}>
				{children}
			</EaContext.Provider>
		</>
	);
};

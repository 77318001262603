import { commonService } from '.';

const getAll = (body) => {
  return commonService.getWithParam('Profile/all', body);
};

const getById = (body) => {
  return commonService.getWithParam('Profile/getById', body);
};

const getSkills = (body) => {
  return commonService.getWithParam('Profile/getSkills', body);
};

const getSkillsV2 = (body) => {
  return commonService.getWithParam('Profile/getSkills_v2', body);
};

const getAllFrameDC = (body) => {
  return commonService.get('Profile/frameDC');
};

const getRoleInternal = () => {
  return commonService.getWithParam('RoleInternal/GetAll');
};

const getRoleInternalById = (id) => {
  return commonService.getWithParam('RoleInternal/GetById?id=' + id);
};

const updateUserRoleInternal = (data) => {
  return commonService.post(`RoleInternal/UpdateUserRoleInternal`, data);
};

const getUserRoleInfo = (search) => {
  return commonService.getWithParam('RoleInternal/userRoleInfo?searchTerm=' + search);
};

const getUserRoleInfoById = (id) => {
  return commonService.getWithParam('RoleInternal/userRoleInfoById?userId=' + id);
};

const getAllCountries = (userId) => {
  return commonService.getWithParam('RoleInternal/countries?userId=' + userId);
}

export const profileService = {
  getAll,
  getById,
  getSkills,
  getSkillsV2,
  getAllFrameDC,
  getRoleInternal,
  getRoleInternalById,
  updateUserRoleInternal,
  getUserRoleInfo,
  getUserRoleInfoById,
  getAllCountries
};

import { Box, Container, Card, Typography, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';

const LoginWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(30)};
`
);

function NotAllowed() {
  return (
    <LoginWrapper
      style={{
        backgroundImage: 'url(/static/images/tools/aste-skills-image-fond.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100%'
      }}
    >
      <Helmet>
        <title>My Skills</title>
      </Helmet>
      <Container maxWidth="lg" >
        <Box display="flex" justifyContent="center" py={5} alignItems="center" sx={{ marginTop: 15 }}>
          <Card sx={{ p: 5, width: 550 }} >
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt="Ligne vert"
                  src="/static/images/logo/Tracé 41.svg"
                  style={{ height: 30 }}
                />
                <img
                  alt="Ligne vert"
                  src="/static/images/logo/Groupe 1.svg"
                  style={{ margin: 5, height: 21 }}
                />
              </Grid>
            </Grid>
            <Grid container
              direction="row"
              alignItems="center">
              <Grid item xs={8}>
                <TypographyH1 color="primary" sx={{ mb: 2, marginBottom: 0 }} variant="h1" >
                  MY SKILLS
                </TypographyH1>
              </Grid>
            </Grid>
            <Trans>mobileVersion</Trans>
          </Card>
        </Box>
      </Container>
    </LoginWrapper>
  );
}

export default NotAllowed;

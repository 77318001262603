import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Trans, useTranslation } from 'react-i18next'
import { authService, userService } from 'src/services';
import { User } from 'src/models/user/user.model';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { CURRENT_LANG, CURRENT_USER, TOKEN_USER, TOKEN_USER_ORI } from 'src/constants';
import { authActions } from 'src/redux/actions';
import { useAppDispatch } from 'src/redux/hook';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import { authProvider } from 'src/services/auth.provider';
import { store } from 'src/redux/store';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ASMDialogSessionExpired from 'src/components/Dialog/ASMDialogSessionExpired';
import { jwt } from 'src/utils/jwt';
import ASMAlert from 'src/components/Alert';

const HeaderWrapper = styled(Grid)(
  ({ theme }) => `
    height: 60px;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${theme.header.background};
    box-shadow: ${theme.header.boxShadow};
    position: fixed;
    justify-content: space-between;
    width: 100%;
`
);

const titleStyle = {
  color: '#2d2927',
  fontSize: '22px',
  marginBottom: '0.5rem',
  marginTop: '0.5rem',
  fontFamily: 'inherit',
  fontWeight: 600,
  lineHeight: '1.5'
};

function Header(props) {
  const {
    REACT_APP_DEV_ONBOARDINGURL,
    REACT_APP_PP_ONBOARDINGURL,
    REACT_APP_PROD_ONBOARDINGURL,
    REACT_APP_NOTIFICATION_DURATION
  } = process.env;
  // // TEST CACHE

  // // Function to add our give multiple cache data
  // const addMultipleCacheData = async (cacheList) => {

  //   for (let i = 0; i < cacheList.length; i++) {
  //     // Converting our response into Actual Response form
  //     const data = new Response(JSON.stringify(cacheList[i].cacheData));

  //     if ('caches' in window) {
  //       // Opening given cache and putting our data into it
  //       let cache = await caches.open(cacheList[i].cacheName)
  //       cache.put(cacheList[i].url, data);
  //     }
  //   }
  //   alert('Multiple Cache Stored!')
  // };

  // const CacheToBeStored = [
  //   {
  //     cacheName: 'CacheOne', cacheData: '1 CacheData',
  //     url: 'https://localhost:300'
  //   },
  //   {
  //     cacheName: 'CacheTwo', cacheData: '2 CacheData',
  //     url: 'https://localhost:300'
  //   },
  //   {
  //     cacheName: 'CacheThree', cacheData: '3rd CacheData',
  //     url: 'https://localhost:300'
  //   },
  // ]
  // // TEST CACHE
  let navigate = useNavigate();
  const [headerData, setHeaderData] = useState({
    username: '',
    role: ''
  });
  const { t, i18n } = useTranslation();
  const [titleData, setTitleData] = useState(props.headerAddon);
  const [userData, setUserData] = useState<User>(JSON.parse(localStorage.getItem(CURRENT_USER)));
  const [culture, isFrench] = useState(localStorage.getItem(CURRENT_LANG) === 'en' || false);
  const username = headerData.username + " (" + t(headerData.role) + ")";
  const usernameTitle = userData?.firstName + " " + userData?.lastName + " (" + t(headerData.role) + ")";
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [userAccount, setUserAccount] = useState<User[]>([]);
  const [displayAlertError, setDisplayAlertError] = useState<boolean>(true);

  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  const url = `${protocol}//${domain}${port ? ":" + port : ""}`;
  const isOnBoarding = (url === REACT_APP_DEV_ONBOARDINGURL) || (url === REACT_APP_PP_ONBOARDINGURL) || (url === REACT_APP_PROD_ONBOARDINGURL);

  // 
  // const [openDialogExpired, setOpenDialogExpired] = useState(false);
  // const isTokenExpired = jwt.isExpired();
  // if (isTokenExpired) {
  //   localStorage.removeItem(TOKEN_USER);
  //   localStorage.removeItem(TOKEN_USER_ORI);
  //   localStorage.removeItem(CURRENT_USER);
  //   localStorage.removeItem(CURRENT_LANG);
  //   setOpenDialogExpired(true);
  // }
  // 
  useEffect(() => {
    if (userData !== null) {
      setHeaderData({
        // username: userData.firstName + " " + userData.lastName.charAt(0) + ".",
        username: userData.firstName + " " + userData.lastName,
        role: userData.role.name
      });

      if (isOnBoarding) {
        userService.getUserAccount(userData.email, 0).then(response => {
          if (response.data.success) {
            setUserAccount(response.data.data)
          } else {

          }
        });
      }
      else {
        userService.getUserAccount(userData.email, 1).then(response => {
          if (response.data.success) {
            setUserAccount(response.data.data)
          } else {

          }
        });
      }
    }
  }, []);

  const handleLogout = () => {
    dispatch(authActions.logout());
    localStorage.removeItem(TOKEN_USER);
    localStorage.removeItem(TOKEN_USER_ORI);
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(CURRENT_LANG);
    navigate('/', { replace: true });
  }

  const changeCulture = (x) => {
    setLoading(true);
    isFrench(x);
    const lan = x ? 'en' : 'fr'
    localStorage.setItem(CURRENT_LANG, lan);
    i18n.changeLanguage(lan);
    const currentLocation = window.location.href;
    userService.updateLang({ idUser: userData.id, idLang: x ? 2 : 1 }).then(response => {
      if (response.data.success) {
        window.location.href = currentLocation;
      } else {
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
    });
  }

  const renderLangs = () => {
    return (
      <Grid item xs={1} >
        <h4 style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
          <a id="fr"
            onClick={() => changeCulture(false)}
            style={{
              paddingLeft: 0, paddingRight: 0,
              textDecoration: 'none',
              color: '#2d2927',
              cursor: 'pointer'
            }}>
            <span style={{
              marginLeft: '0.25rem',
              boxSizing: 'border-box',
              fontWeight: culture ? 'normal' : 900,
              margin: 4
            }}>FR</span></a>
          |
          <a id="en"
            onClick={() => changeCulture(true)}
            style={{
              paddingLeft: 0, paddingRight: 0,
              textDecoration: 'none',
              fontWeight: 'normal',
              color: '#2d2927',
              cursor: 'pointer'
            }}><span style={{
              marginLeft: '0.25rem',
              boxSizing: 'border-box',
              fontWeight: culture ? 900 : 'normal',
              margin: 4
            }}>EN</span></a>
        </h4>
      </Grid>
    );
  }

  const [userCurrrentRole, setUserCurrrentRole] = useState(userData.id);

  const handleChangeUserCurrrentRole = (event) => {
    setUserCurrrentRole(event.target.value);
    setLoading(true);
    authService.changeRole(event.target.value).then(response => {
      if (response.data.success) {
        localStorage.setItem(CURRENT_USER, JSON.stringify(response.data.data));
        localStorage.setItem(TOKEN_USER, response.data.data.token);

        const userDataFromBase: User = JSON.parse(localStorage.getItem(CURRENT_USER));
        setUserData(userDataFromBase);
        if (userDataFromBase.role.id > 2) {
          window.location.href = "/page/management";
        } else {
          window.location.href = "/page/accueil";
        }
      } else {
        setLoading(false);
        setDisplayAlertError(true);
        setTimeout(() => {
          setDisplayAlertError(false);
        }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
      }
    }).catch(err => {
      if (err.response.data.message == "NO_ACCESS") {
        window.location.href = "/page/accueil";
      }
      setLoading(false);
      setDisplayAlertError(true);
      setTimeout(() => {
        setDisplayAlertError(false);
      }, parseInt(REACT_APP_NOTIFICATION_DURATION) * 1000);
    });
  };

  const handleGuideDC = () => {
    const filePath = '/static/docs/Guide_DC_fr.pdf';
    window.open(filePath, '_blank');
  };

  const handleGuideEEP = () => {
    const filePath = '/static/docs/Guide_EEP_fr.pdf';
    window.open(filePath, '_blank');
  };

  const handleNoticeDC = () => {
    const filePath = '/static/docs/Notice_DC_fr.pdf';
    window.open(filePath, '_blank');
  };

  const handleMemoCandidat = () => {
    const filePath = '/static/docs/Memo_Candidat_fr.pdf';
    window.open(filePath, '_blank');
  };

  return (
    <>
      {loading && (
        <><SuspenseLoader /></>
      )}
      <HeaderWrapper display="flex" alignItems="center">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ textAlign: 'center' }}
        >
          <Grid item xs={2} component={Link} to="/page/accueil" style={{ textAlign: 'right' }} >
            <img
              alt="Ligne vert"
              src="/static/images/logo/Tracé 41.svg"
              style={{ height: 40 }}
            />
            <img
              alt="Ligne vert"
              src="/static/images/logo/Groupe 1.svg"
              style={{ height: 34 }}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: 10 }}>
            <p style={{
              textAlign: 'left',
              margin: 0,
              color: '#2d2927',
              fontSize: 12,
              marginTop: 21
            }}><Trans>question</Trans></p>
            <p style={{
              textAlign: 'left',
              fontSize: 12,
              marginTop: -4
            }}>
              <Link
                to='#'
                onClick={(e) => {
                  window.location.href = "mailto:support-myskills@groupeastek.fr";
                  e.preventDefault();
                }}
              >
                support-myskills@groupeastek.fr
              </Link>
            </p>
          </Grid>
          <Grid item xs={4}>
            <Grid container direction="column" width={450}>
              <Grid item xs={12}>
                <h1 style={titleStyle}>
                  MY SKILLS
                  <Trans>{titleData}</Trans>
                </h1>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" marginTop={-1} alignItems="center" justifyContent={'center'}>
                  {headerData.role !== 'Candidate' && (
                    <>
                      <Grid item>
                        <span onClick={handleGuideDC} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                          Guide DC
                        </span>
                      </Grid>
                      <Grid item>
                        <span onClick={handleNoticeDC} style={{ marginLeft: 30, textDecoration: 'underline', cursor: 'pointer' }}>
                          Notice DC
                        </span>
                      </Grid>
                      <Grid item>
                        <span onClick={handleGuideEEP} style={{ marginLeft: 30, textDecoration: 'underline', cursor: 'pointer' }}>
                          Guide EEP
                        </span>
                      </Grid>
                    </>
                  )}
                  {headerData.role === 'Candidate' && (
                    <>
                      <Grid item>
                        <span onClick={handleMemoCandidat} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                          Mémo
                        </span>
                      </Grid>
                      <Grid item>
                        <span onClick={handleGuideDC} style={{ marginLeft: 30, textDecoration: 'underline', cursor: 'pointer' }}>
                          Guide DC
                        </span>
                      </Grid>
                      <Grid item>
                        <span onClick={handleNoticeDC} style={{ marginLeft: 30, textDecoration: 'underline', cursor: 'pointer' }}>
                          Notice DC
                        </span>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {renderLangs()}
          <Grid item xs={2} style={{ paddingRight: 10 }}>

            {userData !== null &&
              <>
                {userAccount.length >= 2 &&
                  <FormControl fullWidth>
                    <Select
                      inputProps={{ 'aria-label': 'Without label' }}
                      value={userCurrrentRole}
                      onChange={handleChangeUserCurrrentRole}
                      style={{
                        width: '120%',
                        color: "#000",
                        paddingLeft: 15,
                        height: 40,
                        marginLeft: -15,
                        fontWeight: 700,
                        marginTop: 6
                      }}
                    >
                      {userAccount.map((option) => (
                        option.isActive &&
                        <MenuItem key={option.id} value={option.id} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 700, color: "#000" }} >
                          {option.firstName + " " + option.lastName + " (" + t(option.role.name) + ")"}
                        </MenuItem>
                      )
                      )}
                    </Select>
                  </FormControl>
                }
                {userAccount.length < 2 &&
                  <h4
                    title={usernameTitle}
                    style={{
                      width: '120%',
                      color: "#000",
                      paddingLeft: 15,
                      height: 40,
                      marginLeft: -15,
                      fontWeight: 700,
                      marginTop: 44,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {username}
                  </h4>
                }
              </>
            }
          </Grid>
          <Grid item xs={1}>
            <AzureAD provider={authProvider} reduxStore={store}>
              {({ logout, authenticationState }) => {
                const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                if (isAuthenticated) {
                  return (
                    <Fragment>
                      <Button variant="contained"
                        color="secondary"
                        title={t('logoutLabel')}
                        onClick={logout}
                        style={{ marginLeft: 10, minWidth: 34, maxWidth: 34, minHeight: 34, maxHeight: 34, borderRadius: '50%' }}
                      >
                        <ExitToAppIcon style={{ fontSize: 28 }} />
                      </Button>
                    </Fragment>
                  );
                } else if (isUnauthenticated) {
                  return (
                    <Fragment>
                      <Button variant="contained"
                        color="secondary"
                        title={t('logoutLabel')}
                        onClick={handleLogout}
                        style={{ marginLeft: 10, minWidth: 34, maxWidth: 34, minHeight: 34, maxHeight: 34, borderRadius: '50%' }}
                      >
                        <ExitToAppIcon style={{ fontSize: 28 }} />
                      </Button>
                    </Fragment>
                  );
                }
              }}
            </AzureAD>
          </Grid>
        </Grid>
        {/* TEST CACHE */}
        {/* <button onClick={() => addMultipleCacheData(CacheToBeStored)} >
          Add Multiple Caches</button> */}
        {/* TEST CACHE */}
      </HeaderWrapper>
      {/* Dialog */}
      {/* <ASMDialogSessionExpired
        open={openDialogExpired}
        onClose={() => window.location.href = "/"}
      /> */}
      {/*  */}
      {/* <ASMAlert message={t('action.error')} severity="error" open={displayAlertError} /> */}
    </>
  );
}

export default Header;


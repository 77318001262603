import { commonService } from '.';

const getListeDc = (body) => {
    return commonService.getWithParam('ListeDc/all', body);
};

const getListeKP = (idCurrentUser) => {
    return commonService.get('ListeDc/listeKP?idCurrentUser=' + idCurrentUser);
};

const getSearchKp = (body) => {
    return commonService.getWithParam('ListeDc/listSearchKp', body);
};


const getListeFiltreKp = (body) => {
    return commonService.getWithParam('ListeDc/filtreKp', body);
}
const getListeFiltreSkill = (body) => {
    return commonService.getWithParam('ListeDc/filtreSkill', body);
}

const getListSkills = () => {
    return commonService.get('ListeDc/listSkills');
};

const insertSkills = (idSkill, body) => {
    return commonService.post('ListeDc/insertSkills?idSkill=' + idSkill, body);
};

const deleteSkills = (id) => {
    return commonService.deleteWithParam('ListeDc/deleteSkills?idSkill=' + id);
};


const getListSkillById = (body) => {
    return commonService.getWithParam('ListeDc/listSkillById', body);
};

const getListSkillCategory = () => {
    return commonService.get('ListeDc/listSkillCategory');
};


const getSearchSkills = (body) => {
    return commonService.getWithParam('ListeDc/listSearchSkills', body);
};

const getListOtherSkills = (idCurrentUser) => {
    return commonService.get('ListeDc/listOtherSkills?idCurrentUser=' + idCurrentUser);
};

const getSearchOtherSkills = (body) => {
    return commonService.getWithParam('ListeDc/listSearchOtherSkills', body);
};

const getListeRechercheDc = (body) => {
    return commonService.getWithParam('ListeDc/recherche', body);
};

const getListeRechercheType = (body) => {
    return commonService.getWithParam('ListeDc/filtreType', body);
};

const getListeFiltreComptence = (body) => {
    return commonService.getWithParam('ListeDc/filtreParCompetence', body);
}

const getListeCritereComptence = (body, param) => {
    return commonService.postBodyWithParam('ListeDc/filtreCritereCompetence', body, param);
}

export const listeDcService = {
    getListeDc,
    getListeKP,
    getSearchKp,
    getListeFiltreKp,
    getListeFiltreSkill,
    getListSkills,
    insertSkills,
    deleteSkills,
    getListSkillById,
    getListSkillCategory,
    getSearchSkills,
    getListOtherSkills,
    getSearchOtherSkills,
    getListeRechercheDc,
    getListeRechercheType,
    getListeFiltreComptence,
    getListeCritereComptence
};

